<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card :key="cardKey">
          <!--          <l-map-->
          <!--            style="height: 500px; border: #2a6f39 1px solid;"-->
          <!--            :zoom="zoom"-->
          <!--            :center="[11.905720, -1.293255]"-->
          <!--          >-->
          <!--            <l-tile-layer-->
          <!--              :url="url"-->
          <!--              :attribution="attribution"-->
          <!--            />-->
          <!--            <div-->
          <!--              v-for="commercial in commerciaux"-->
          <!--              :key="commercial.uuid"-->
          <!--            >-->
          <!--              <l-marker-->
          <!--                v-if="commercial.is_online"-->
          <!--                :lat-lng="[commercial.latitude, commercial.longitude]"-->
          <!--                :icon="iconOnline"-->
          <!--              >-->
          <!--                <l-popup :content="commercial.full_name" />-->
          <!--              </l-marker>-->
          <!--              <l-marker-->
          <!--                v-if="!commercial.is_online"-->
          <!--                :lat-lng="[commercial.latitude, commercial.longitude]"-->
          <!--                :icon="iconOffline"-->
          <!--              >-->
          <!--                <l-popup :content="commercial.full_name" />-->
          <!--              </l-marker>-->
          <!--            </div>-->

          <!--          </l-map>-->

          <l-map
            style="height: 500px"
            :zoom="zoom"
            :center="center"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />
            <!--                        <l-marker :lat-lng="markerLatLng" :icon="icon" v-if="visite">-->
            <!--                            <l-popup :content="visite.customer.name" />-->
            <!--                        </l-marker>-->
            <div v-if="commerciaux && !commercialToShow" >
              <l-marker
                v-for="commercial in commerciaux"
                v-if="commercial.is_online"
                :key="commercial.id"
                :lat-lng="[commercial.latitude, commercial.longitude]"
                :icon="iconOnline"
              >
                <l-popup :content="commercial.full_name" />
              </l-marker>
              <l-marker
                v-for="commercial in commerciaux"
                v-if="!commercial.is_online && (commercial.latitude && commercial.longitude)"
                :key="commercial.id"
                :lat-lng="[commercial.latitude, commercial.longitude]"
                :icon="iconOffline"
              >
                <l-popup :content="commercial.full_name" />
              </l-marker>
            </div>
            <div v-if="commercialToShow">
              <l-marker
                v-if="commercialToShow.is_online"
                :key="commercialToShow.id"
                :lat-lng="[commercialToShow.latitude, commercialToShow.longitude]"
                :icon="iconOnline"
              >
                <l-popup :content="commercialToShow.full_name" />
              </l-marker>
              <l-marker
                v-if="!commercialToShow.is_online && (commercialToShow.latitude && commercialToShow.longitude)"
                :key="commercialToShow.id"
                :lat-lng="[commercialToShow.latitude, commercialToShow.longitude]"
                :icon="iconOffline"
              >
                <l-popup :content="commercialToShow.full_name" />
              </l-marker>
            </div>

          </l-map>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            :key="componentKey"
            ref="myTable"
            :columns="columns"
            :rows="commerciaux"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'nbr_tournes'">

                {{ props.row.nbr_tournes_ok }} /

              </span>
              <span v-if="props.column.field === 'is_online'">
                <b-badge
                  v-if="props.row.is_online"
                  variant="light-success"
                >
                  En ligne
                </b-badge>
                <b-badge
                  v-if="!props.row.is_online"
                  variant="light-danger"
                >
                  Non active depuis 30min
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <!--                  <feather-icon-->
                <!--                    icon="Edit2Icon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span>Edit</span>-->
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="voir(props.row)"
                  >
                    Voir
                  </b-button>
                </b-button-group>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BBadge,
  BPagination,
  BButtonGroup,
  BButton,
  BCard,
  BFormInput,
  BDropdownItem,
  BFormGroup,
  BDropdown,
} from 'bootstrap-vue'
// import LeafletMarkerCirclePolygon from './LeafletMarkerCirclePolygon.vue'
// import LeafletPopup from './LeafletPopup.vue'
// import LeafletGeoJSON from './LeafletGeoJSON.vue'
import { icon } from 'leaflet'
import { API_URL } from '@/helpers/global-scops'
import LMap from 'vue2-leaflet/src/components/LMap.vue'
import LTileLayer from 'vue2-leaflet/src/components/LTileLayer.vue'
import LMarker from 'vue2-leaflet/src/components/LMarker.vue'
import LPopup from 'vue2-leaflet/src/components/LPopup.vue'
import 'leaflet/dist/leaflet.css'
import store from '@/store'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'

export default {
  components: {
    BDropdown,
    BFormGroup,
    BDropdownItem,
    BFormInput,
    BCard,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BPagination,
    BBadge,
    BFormSelect,
    LPopup,
    LMarker,
    LTileLayer,
    LMap,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  data() {
    return {
      uuid: null,
      cardKey: 0,
      componentKey: 0,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [12.6494, -8.0005],
      markerLatLng: [12.6494, -8.0005],
      iconOnline: icon({
        iconUrl: 'markers/user_online.png',
        iconSize: [16, 16],
        iconAnchor: [16, 6],
      }),
      iconOffline: icon({
        iconUrl: 'markers/user_offline.png',
        iconSize: [16, 16],
        iconAnchor: [16, 6],
      }),
      commerciaux: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Date/Heure',
          field: 'datetime_fr',
          filterable: true,
        },
        {
          label: 'Prénom',
          field: 'first_name',
          filterable: true,
        },
        {
          label: 'Nom',
          field: 'last_name',
          filterable: true,
        },
        {
          label: 'Telephone',
          field: 'telephone',
          filterable: true,
        },
        {
          label: 'Responsable',
          field: 'responsable_r.full_name',
          filterable: true,
        },
        {
          label: 'Nombre de distributeurs',
          field: 'nbr_distributeur',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de PDV',
          field: 'nbr_pdv',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de visites PDV réalisé',
          field: 'nbr_visites_pdv',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de visites Distributeur réalisé',
          field: 'nbr_visites_distributeur',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de tournées cloturées',
          field: 'nbr_tournes',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Etat',
          field: 'is_online',
          filterable: true,
        },
        {
          label: 'actions',
          field: 'action',
        },
      ],
      refreshIntervalId: null,
      commercialToShow: null,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.$http.get(`${API_URL}commercials-tracking/`)
      .then(response => {
        this.commerciaux = response.data
      })
      .catch(error => {
        console.log(error)
      })
    this.getCommercials()
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.commerciaux.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    forceRerender() {
      this.cardKey += 1
    },
    getCommercials() {
      this.refreshIntervalId = setInterval(() => {
        this.$http.get(`${API_URL}commercials-tracking/`)
          .then(response => {
            this.commerciaux = response.data
            // this.forceRerender()
          })
          .catch(error => {
            console.log(error)
          })
      }, 20000)
    },
    // checke if the commercial last_geolocation is less than 5 minutes
    isOnline(commercial) {
      const now = new Date()
      const lastGeolocation = new Date(commercial.last_geolocation_time)
      const diff = now - lastGeolocation
      const minutes = Math.floor(diff / 1000 / 60)
      console.log(diff, minutes, lastGeolocation, now)
      return minutes < 5
    },
    voir(commercial) {
      this.commercialToShow = commercial
    },
    voirTout() {
      this.commercialToShow = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
